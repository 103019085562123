import { ReactNode, useEffect } from 'react'

import {
  Avatar,
  Box,
  BoxProps,
  Collapse,
  Container,
  Divider,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import * as packageJson from 'package.json'
import { IconType } from 'react-icons'
import { FiChevronDown, FiChevronRight, FiMenu } from 'react-icons/fi'

import { Link, useLocation } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import { globalConfig } from 'src/config/globalConfig'
import { LinkItemGroup, LinkItemProps } from 'src/layouts/MainLayout/MainLayout'

import { AlignEnd } from '../Shared/AlignEnd/AlignEnd'
import LanguageSwitcher from '../Shared/LanguageSwitcher/LanguageSwitcher'

interface SidebarProps extends BoxProps {
  groups: Array<LinkItemGroup>
  onClose: () => void
}

interface NavGroupProps extends FlexProps {
  items: Array<LinkItemProps>
  defaultIsOpen?: boolean
  children: ReactNode
}

interface NavItemProps extends FlexProps {
  to?: string
  icon?: IconType
  isSubItem?: boolean
  defaultIsOpen?: boolean
  items?: Array<LinkItemProps>
  children: ReactNode
}

export default function SidebarWithHeader({
  children,
  linkItemGroups,
}: {
  children: ReactNode
  linkItemGroups: LinkItemGroup[]
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const location = useLocation()

  useEffect(() => {
    // Close side menu
    onClose()
  }, [location, onClose])

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.50', 'gray.900')}>
      <SidebarContent
        groups={linkItemGroups}
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        isOpen={isOpen}
        placement="start"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent groups={linkItemGroups} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box
        ms={{ base: 0, md: 60 }}
        py={[2, 2, 4, 6, 8]}
        px={[4, 4, 6, 8, 10, 20]}
      >
        {/* <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="#777" />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="#">Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#">About</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Contact</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb> */}
        <Container maxW={'1600px'} p={0} mt={6}>
          <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
          {children}
          <AlignEnd>
            <Box mt={6} opacity={0.4} fontSize={'smaller'}>
              v{packageJson.version}
            </Box>
          </AlignEnd>
        </Container>
      </Box>
    </Box>
  )
}

const SidebarContent = ({ groups, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      color={'whiteAlpha.700'}
      bg={'brand.900'}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      overflowY={'auto'}
      {...rest}
    >
      <Flex
        h={24}
        maxWidth="140"
        mx="6"
        alignItems="center"
        justifyContent="space-between"
      >
        <img src="/img/youcc-logo.png" alt="YouCC" />
      </Flex>
      {groups.map((group) => {
        return (
          <NavGroup
            key={group.name}
            defaultIsOpen={group.defaultIsOpen}
            items={group.items}
          >
            {group.name}
          </NavGroup>
        )
      })}
    </Box>
  )
}

const NavGroup = ({ items, children }: NavGroupProps) => {
  return (
    <>
      <Flex
        align="center"
        px="6"
        py="2"
        fontSize={12}
        textTransform={'uppercase'}
      >
        {children}
      </Flex>
      {items.map((link) => {
        if (link.items) {
          return (
            <NavItemCollapsible
              key={link.name}
              icon={link.icon}
              defaultIsOpen={link.defaultIsOpen}
              items={link.items}
            >
              {link.name}
            </NavItemCollapsible>
          )
        }

        return (
          <NavItem key={link.name} to={link.to} icon={link.icon}>
            {link.name}
          </NavItem>
        )
      })}
      <Box px={4} py={4}>
        <Divider opacity={0.2} />
      </Box>
    </>
  )
}

const NavItem = ({ to, icon, isSubItem, children, ...rest }: NavItemProps) => {
  return (
    <Link
      to={to}
      style={{ textDecoration: 'none' }}
      // _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        px="6"
        py="2"
        fontSize={isSubItem ? 14 : 16}
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'brand.900',
          color: 'white',
        }}
        {...rest}
      >
        {icon ? (
          <Icon
            me="4"
            fontSize="14"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        ) : (
          <Icon mr="4" fontSize="14" color={'transparent'} />
        )}
        {children}
      </Flex>
    </Link>
  )
}

const NavItemCollapsible = ({
  icon,
  items,
  defaultIsOpen,
  children,
  ...rest
}: NavItemProps) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  return (
    <>
      <Link onClick={onToggle} style={{ textDecoration: 'none' }} to={'#'}>
        <Flex
          align="center"
          px="6"
          py="2"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'brand.900',
            color: 'white',
          }}
          {...rest}
        >
          {icon && (
            <Icon
              me="4"
              fontSize="14"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
          <Spacer />
          <Icon as={isOpen ? FiChevronDown : FiChevronRight}></Icon>
        </Flex>
      </Link>
      <Collapse in={isOpen} animateOpacity>
        {items.map((link) => {
          if (link.items) {
            return (
              <NavItemCollapsible
                key={link.name}
                icon={link.icon}
                defaultIsOpen={defaultIsOpen}
                items={link.items}
              >
                {link.name}
              </NavItemCollapsible>
            )
          }

          return (
            <NavItem key={link.name} to={link.to} isSubItem={true}>
              {link.name}
            </NavItem>
          )
        })}
      </Collapse>
    </>
  )
}

interface MobileProps extends FlexProps {
  onOpen: () => void
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { isAuthenticated, currentUser, logOut } = useAuth()

  return (
    <Flex
      ms={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      {/* 
      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text> */}

      <HStack
        spacing={{ base: '3', md: '6' }}
        visibility={isAuthenticated ? 'visible' : 'hidden'}
      >
        {globalConfig.isDev && <LanguageSwitcher />}
        {globalConfig.environmentName &&
          globalConfig.environmentName !== 'production' && (
            <Tag colorScheme={'blue'} size={'lg'}>
              {globalConfig.environmentName.toUpperCase()}
            </Tag>
          )}
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              {currentUser && (
                <HStack>
                  <Avatar
                    size={'sm'}
                    name={currentUser.fullName ?? currentUser.email}
                    pt={'3px'}
                  />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">{currentUser?.email}</Text>
                    <Text fontSize="xs" color="gray.600">
                      Admin
                    </Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              )}
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuDivider />
              <MenuItem onClick={logOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}
