import { PrivateSet, Route, Router, Set } from '@redwoodjs/router'

import ScaffoldLayout from 'src/layouts/ScaffoldLayout'

import { useAuth } from './auth'
import AuthLayout from './layouts/AuthLayout/AuthLayout'
import MainLayout from './layouts/MainLayout/MainLayout'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Set wrap={ScaffoldLayout} title="Files" titleTo="files" buttonLabel="New File" buttonTo="newFile">
        <Route path="/files/new" page={FileNewFilePage} name="newFile" />
        <Route path="/files/{id:Int}/edit" page={FileEditFilePage} name="editFile" />
        <Route path="/files/{id:Int}" page={FileFilePage} name="file" />
        <Route path="/files" page={FileFilesPage} name="files" />
      </Set>
      <Set wrap={AuthLayout}>
        <Route path="/login" page={LoginPage} name="login" />
        {/* <Route path="/signup" page={SignupPage} name="signup" /> */}
        <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
        <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
      </Set>
      <PrivateSet wrap={MainLayout} unauthenticated="login">
        <Route path="/" page={HomePage} name="home" />
        <Route path="/testing" page={TestingPage} name="testing" />

        <PrivateSet unauthenticated="home" roles={'admin'}>
          <Set wrap={ScaffoldLayout} title="Users" titleTo="users" buttonLabel="New User" buttonTo="newUser">
            <Route path="/users/new" page={UserNewUserPage} name="newUser" />
            <Route path="/users/{id:Int}/edit" page={UserEditUserPage} name="editUser" />
            <Route path="/users/{id:Int}" page={UserUserPage} name="user" />
            <Route path="/users" page={UserUsersPage} name="users" />
          </Set>
        </PrivateSet>

        <Set wrap={ScaffoldLayout} title="Customers" titleTo="customers" buttonLabel="New Customer" buttonTo="newCustomer">
          <Route path="/customers/new" page={CustomerNewCustomerPage} name="newCustomer" />
          <Route path="/customers/{id:Int}/edit" page={CustomerEditCustomerPage} name="editCustomer" />
          <Route path="/customers/{id:Int}" page={CustomerCustomerPage} name="customer" />
          <Route path="/customers" page={CustomerCustomersPage} name="customers" />
        </Set>

        <Set wrap={ScaffoldLayout} title="CustomerContacts" titleTo="customerContacts" buttonLabel="New CustomerContact" buttonTo="newCustomerContact">
          <Route path="/customer-contacts/new" page={CustomerContactNewCustomerContactPage} name="newCustomerContact" />
          <Route path="/customer-contacts/{id:Int}/edit" page={CustomerContactEditCustomerContactPage} name="editCustomerContact" />
          <Route path="/customer-contacts/{id:Int}" page={CustomerContactCustomerContactPage} name="customerContact" />
          <Route path="/customer-contacts" page={CustomerContactCustomerContactsPage} name="customerContacts" />
        </Set>

        <Set wrap={ScaffoldLayout} title="PurchaseOrders" titleTo="purchaseOrders" buttonLabel="New PurchaseOrder" buttonTo="newPurchaseOrder">
          <Route path="/purchase-orders" page={PurchaseOrderPurchaseOrdersPage} name="purchaseOrders" />
        </Set>

        <Set wrap={ScaffoldLayout} title="Suppliers" titleTo="suppliers" buttonLabel="New Supplier" buttonTo="newSupplier">
          <Route path="/suppliers/new" page={SupplierNewSupplierPage} name="newSupplier" />
          <Route path="/suppliers/{id:Int}/edit" page={SupplierEditSupplierPage} name="editSupplier" />
          <Route path="/suppliers/{id:Int}" page={SupplierSupplierPage} name="supplier" />
          <Route path="/suppliers" page={SupplierSuppliersPage} name="suppliers" />
        </Set>

        <Set wrap={ScaffoldLayout} title="Employees" titleTo="employees" buttonLabel="New Employee" buttonTo="newEmployee">
          <Route path="/employees/new" page={EmployeeNewEmployeePage} name="newEmployee" />
          <Route path="/employees/{id:Int}/edit" page={EmployeeEditEmployeePage} name="editEmployee" />
          <Route path="/employees/{id:Int}" page={EmployeeEmployeePage} name="employee" />
          <Route path="/employees" page={EmployeeEmployeesPage} name="employees" />
          <Route path="/employee-timesheet" page={EmployeeTimesheetPage} name="employeeTimesheet" />
        </Set>

        <Set wrap={ScaffoldLayout} title="EmployeeMonthlySalaries" titleTo="employeeMonthlySalaries" buttonLabel="New Employee Monthly Salary" buttonTo="newEmployeeMonthlySalary">
          <Route path="/employee-monthly-salaries" page={EmployeeMonthlySalaryEmployeeMonthlySalariesPage} name="employeeMonthlySalaries" />
        </Set>

        <Set wrap={ScaffoldLayout} title="Notes" titleTo="notes" buttonLabel="New Note" buttonTo="newNote">
          <Route path="/notes/new" page={NoteNewNotePage} name="newNote" />
          <Route path="/notes/{id:Int}/edit" page={NoteEditNotePage} name="editNote" />
          <Route path="/notes/{id:Int}" page={NoteNotePage} name="note" />
          <Route path="/notes" page={NoteNotesPage} name="notes" />
        </Set>

        <Set wrap={ScaffoldLayout} title="ToDoDefinitions" titleTo="toDoDefinitions" buttonLabel="New ToDoDefinition" buttonTo="newToDoDefinition">
          <Route path="/to-do-definitions/new" page={ToDoDefinitionNewToDoDefinitionPage} name="newToDoDefinition" />
          <Route path="/to-do-definitions/{id:Int}/edit" page={ToDoDefinitionEditToDoDefinitionPage} name="editToDoDefinition" />
          <Route path="/to-do-definitions/{id:Int}" page={ToDoDefinitionToDoDefinitionPage} name="toDoDefinition" />
          <Route path="/to-do-definitions" page={ToDoDefinitionToDoDefinitionsPage} name="toDoDefinitions" />
        </Set>

        <Set title="Settings" titleTo="Settings">
          <Route path="/currency-rates" page={CurrencyRatesPage} name="currencyRates" />
        </Set>

        <PrivateSet unauthenticated="home" roles={['admin', 'finance']}>
          <Route path="/invoices-tools" page={InvoicesToolsPage} name="invoicesTools" />
        </PrivateSet>

        <Set title="Reports" titleTo="reports">
          <Route path="/reports/work-hours" page={ReportsWorkHoursReportPage} name="workHoursReport" />
          <Route path="/reports/employees-salary" page={ReportsEmployeesSalaryReportPage} name="employeesSalaryReport" />
          <Route path="/reports/projects" page={ReportsProjectsReportPage} name="projectsReport" />

          <PrivateSet unauthenticated="home" roles={['admin', 'finance']}>
            <Route path="/recent-invoices" page={RecentInvoicesPage} name="recentInvoices" />
            <Route path="/reports/invoices" page={ReportsInvoicesReportPage} name="invoicesReport" />
            <Route path="/reports/open-invoices" page={ReportsOpenInvoicesReportPage} name="openInvoicesReport" />
            <Route path="/reports/invoice-yearly" page={ReportsInvoiceYearlyReportPage} name="invoiceYearlyReport" />
          </PrivateSet>
        </Set>
      </PrivateSet>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
